import React, { Component, createRef } from 'react';
import moment from 'moment';
import { withRouter } from "react-router";

import './index.css';

import * as helpers from '../../helpers';
import Map from '../Map';
import PanelHeader from '../PanelHeader';
import PanelBottom from '../PanelBottom';
import PanelBody from '../PanelBody';
import PanelTop from '../PanelTop';


class App extends Component {
  constructor(props) {
    super(props);

    const search = new URLSearchParams(window.location.search);

    this.state = {
      date: new Date(),
      datetime_utc: search.get("datetime_utc") || '',
      selected_event: search.get("selected_event") || '',
      isSensorSelected: false,
      vdatumType: 'MLLW', //NAVD 88, MHHW, MLLW
      selectedWaterLevel: 0,
      selectedSensorWaterLevel: 0,
    }

    this.vdatumOffsets = createRef({});

    this.setSensorSelected = this.setSensorSelected.bind(this);
    this.setSelectedWaterLevel = this.setSelectedWaterLevel.bind(this);
    this.setSensorInfo = this.setSensorInfo.bind(this);
  }

  async componentDidMount() {
    let layerDataGeoJSON = await helpers.layerDataToGeoJSON(this.state.datetime_utc);

    this.setState({
      ...layerDataGeoJSON,
    })

    // every minute, trigger a re-render of all components in order to update
    // the times in the panel and the popups
    this.timerID = setInterval(
      () => this.tick(),
      60000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      date: new Date()
    });
  }

  handleLayerChange = (layer, isActive) => {
    let layers = [layer]

    if (layers.length > 0) {
      this.props.history.push(`/?layers=${layers.join()}&datetime_utc=${this.state.datetime_utc}`);
    } else {
      this.props.history.push(`${this.props.location.pathname}`)
    }
  }

  setSensorSelected = (isSensorSelected) => {
    this.setState({ isSensorSelected: isSensorSelected })
  }

  onVdatumChange = (vdatumType) => {
    this.setState({ vdatumType: vdatumType })
  }

  setSelectedWaterLevel(value) {
    this.setState({ selectedWaterLevel: value });
  }

  setSensorInfo(selectedSensorWaterLevel, vdatumOffsets) {
    this.vdatumOffsets.current = vdatumOffsets;
    this.setState({ selectedSensorWaterLevel: selectedSensorWaterLevel, selectedWaterLevel: 0 });
  }

  render() {
    return (
      <div className="app">
        {!this.state.isSensorSelected && this.state.map_updated_at && <div className="update-bar">
          <span>{`Last updated ${moment(this.state.map_updated_at).mapFromNow()}`}</span>
        </div>}
        <Map
          {...this.state}
          setSensorSelected={this.setSensorSelected}
          setSensorInfo={this.setSensorInfo}
          vdatumOffsets={this.vdatumOffsets}
          onVdatumChange={this.onVdatumChange}
        />
        <div className='top-panel'>
          <PanelTop
            {...this.state}
          />
        </div>
        <div className="side-panel">
          <PanelHeader
            {...this.state}
            onLayerChange={this.handleLayerChange}
            setSensorSelected={this.setSensorSelected}
            isSensorSelected={this.state.isSensorSelected}
          />
          <PanelBody
            {...this.state}
          />
        </div>
        {this.state.isSensorSelected && <div className="bottom-panel">
          <PanelBottom
            {...this.state}
            setSelectedWaterLevel={this.setSelectedWaterLevel}
            vdatumOffsets={this.vdatumOffsets}
          />
        </div>}
      </div>
    );
  }
}

export default withRouter(App);
