import axios from 'axios';
import {Line} from 'react-chartjs-2';
import moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from "react-router";
import {
  Switch,
  Route,
} from "react-router-dom";

import './index.css';

import * as API from '../../constants/apiEndpoints';
import Bridges from '../Bridges';
import BridgeStatus from '../BridgeStatus';
import * as helpers from '../../helpers';

class Bridge extends Component {
  render() {
 

    let lineChart = null;
    if (this.props.sensor !== undefined) {
    let vdatum = this.props.vdatumType;
      let measurements = this.props.sensor.measurements.map(measurement => {
        return {...measurement, 
          datum : vdatum,
          water_level: helpers.vdatumConverter(this.props.sensor.vdatum_offsets, measurement.water_level, vdatum)
          }
      });
      let bridgeElevation = helpers.vdatumConverter(this.props.sensor.vdatum_offsets, this.props.bridge.properties.elevation.elevation, vdatum);

      let sensorData = {
        labels: measurements.map(m => { return moment(m.date).format('MMM D, h:mm A') }),
        datasets: [
          {
            label: 'Water level',
            data: measurements.map(m => { return m.water_level }),
            backgroundColor: '#202932',
            borderColor: '#202932',
            // borderWidth: 2,
            fill: false,
          },
          {
            label: 'Bridge beam height',
            data: measurements.map(m => { return bridgeElevation }),
            backgroundColor: '#999',
            borderColor: '#999',
            // borderWidth: 2,
            fill: false,
          }
        ]
      }

      if (measurements.length > 0) {
        lineChart = <div className="chart">
          <p>Water level</p>
          <Line
            data={sensorData}
            options={{
              animation: false,
              elements: {
                point: {
                  radius: 0
                }
              },
              hover: {
                mode: 'nearest',
                intersect: true
              },
              legend:{
                display: true,
                position: 'bottom',
              },
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                xAxes: [{
                  gridLines: {
                    lineWidth: 0
                  },
                  ticks: {
                    autoSkip: true,
                    maxTicksLimit: 7,
                  }
                }],
                yAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: `Feet (${vdatum})`,
                  }
                }],
              },
              tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  label: function(tooltipItem, data) {
                    return `${Number(tooltipItem.yLabel)} ft (${vdatum})`
                  }
                }
              },
            }}
          />          
        </div>
      }
    }

    if (Object.keys(this.props.bridge).length > 0) {
      return (
        <div className="panel-body">
          <div className="content">
            <h2>Bridge status</h2>
            <BridgeStatus 
              id={this.props.bridge.properties.id}
              name={this.props.bridge.properties.name}
              numEvents={this.props.bridge.properties.num_events}
              events={this.props.bridge.properties.events}
              showBridgeName={false}
            />
            {lineChart}
          </div>
        </div>      
      );  
    } else {
      return null;
    }
  }
}

class PanelBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sensor: null,
    }

    this.prevTimeRange = {start: null, end: null};
    this.prevBridge = {properties: {id: -1}};
  }

  getSensor = async (id, start, end) => {
    const datetimeUrlPath = this.props.datetime_utc ? `/${this.props.datetime_utc}` : '';
    let sensor_url = `${API.BRIDGES.replace('/<datetime>', datetimeUrlPath)}/${id}`;
    if (start !== undefined && end !== undefined) {
      sensor_url = `${sensor_url}?start=${start}&end=${end}`;
    }

    let sensor = await axios.get(sensor_url);
    this.setState({
      sensor: sensor.data,
    });
  }

  render() {
    let that = this

    return (
      <Switch>
        <Route exact path="/bridges/:id" render={(props) => {
          if (that.props.bridges !== undefined) {
            let bridge = helpers.getEntity(that.props, 'bridges', props.match.params.id);
            let isSameBridge = that.prevBridge.properties.id === bridge.properties.id;
            that.prevBridge = bridge;

            let timeRange = helpers.getStartEnd(props);
            let hasTimeRange = (Object.keys(timeRange).length > 0);

            let isSameTimeRange = true;
            if (hasTimeRange) {
              isSameTimeRange = that.prevTimeRange.start === timeRange.start && that.prevTimeRange.end === timeRange.end
            }
            that.prevTimeRange = timeRange;

            // if `start` and `end` are present in the query params, and
            // if `start` and `end` are different from the previous request, or the selected bridge itself is different from the previous request,
            // then make a request for the bridge's associated sensor data between `start` and `end`
            let sensor = null;
            if (hasTimeRange && isSameTimeRange && isSameBridge) {
              sensor = that.state.sensor;
            }

            if (!isSameTimeRange || !isSameBridge) {
              that.getSensor(bridge.properties.sensor_id, timeRange.start, timeRange.end);
            }

            if (sensor !== null) {
              return <Bridge bridge={bridge} sensor={sensor} vdatumType={this.props.vdatumType}/>  
            } else {
              return <Bridge bridge={bridge} vdatumType={this.props.vdatumType}/>  
            }
          } else {
            return null;
          }
        }} />
        <Route path="/" render={() => {
          let layers = helpers.getLayers(this.props);
          if (layers.includes('bridges')) {
            return <Bridges 
              bridges={that.props.bridges}
              vdatumType={this.props.vdatumType}
            />
          } else {
            return null;
          }
        }} />
      </Switch>
    )
    
  }
}

export default withRouter(PanelBody);
