import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from "react-router";
import Dropdown from 'react-bootstrap/Dropdown';
import { Steps } from 'intro.js-react';

import './index.css';
import * as API from '../../constants/apiEndpoints';
import * as helpers from '../../helpers';

class PanelTop extends Component {
  
  constructor(props) {
    super(props);

    const props_datetime_local = this.props.datetime_utc ? moment.utc(this.props.datetime_utc, 'YYYY-MM-DDTHH-mm').local().format('YYYY-MM-DDTHH-mm') : '';

    this.state = {
      selected_event: this.props.selected_event || '',
      selected_date: props_datetime_local.split('T')[0] || '',
      selected_time: props_datetime_local.split('T')[1] || '',
      event_list: [],
      date_list_per_event: {},
      time_list_per_date: {},
      disabled: true,
      stepsEnabled: false,
    }

    this.onEventSelection = this.onEventSelection.bind(this);
    this.onDateSelection = this.onDateSelection.bind(this);
    this.onTimeSelection = this.onTimeSelection.bind(this);

    setTimeout(() => {
      this.setState({ disabled: false });
    }, 2500);
  }

  getDatetimeList = async () => {
    let cache_datetime_list = (await axios.get(API.INUNDATION_CACHE_DATETIME_FOLDER_NAMES)).data;
    let archive_datetime_list = (await axios.get(API.INUNDATION_ARCHIVE_DATETIME_FOLDER_NAMES)).data;
    let datetime_list = cache_datetime_list.concat(archive_datetime_list);

    if (!Array.isArray(datetime_list) || datetime_list.length === 0) {
      let layers = await axios.get(API.LAYERS.replace('/<datetime>', ''));
      datetime_list = [layers.data[0].updated_at];
    }

    let local_datetime_list = datetime_list.map((datetime) => moment.utc(datetime, 'YYYY-MM-DDTHH-mm').local().format('YYYY-MM-DDTHH-mm'));

    let time_list_per_date = {};

    local_datetime_list.forEach((datetime) => {
      const date = datetime.split('T')[0];
      const time = datetime.split('T')[1];

      if (!(date in time_list_per_date)) {
        time_list_per_date[date] = [];
      }
      if(!time_list_per_date[date].includes(time)){
        time_list_per_date[date].push(time);
      }
    });

    let date_list_per_event = {
      'All Past Estimates': Object.keys(time_list_per_date),
      ...(await axios.get(API.INUNDATION_FLOODING_EVENT)).data
    };

    this.setState({
      event_list: Object.keys(date_list_per_event),
      date_list_per_event: date_list_per_event,
      time_list_per_date: time_list_per_date,
    });
  }

  onEventSelection(event) {
    this.setState({ selected_event: event.target.getAttribute('value'), selected_date: 'Select', selected_time: 'Select' });
  }

  onDateSelection(event) {
    this.setState({ selected_date: event.target.getAttribute('value'), selected_time: 'Select' });
  }

  onTimeSelection(event) {
    const selectedTime = event.target.getAttribute('value');
    const selectedDateTimeUTC = moment(`${this.state.selected_date}T${selectedTime}`, 'YYYY-MM-DDTHH-mm').utc().format('YYYY-MM-DDTHH-mm');

    let layers = helpers.getLayers(this.props)
    this.props.history.push(`?layers=${layers.join()}&datetime_utc=${selectedDateTimeUTC}&selected_event=${this.state.selected_event}`);
    window.location.reload();
  }


  render() {
    if (this.state.event_list.length === 0) {
      this.getDatetimeList();
    }

    const date_list = this.state.date_list_per_event[this.state.selected_event] || [];
    date_list.sort().reverse();
    const time_list = this.state.time_list_per_date[this.state.selected_date] || [];
    time_list.sort();

    return (<>
      {Object.keys(this.state.date_list_per_event).length > 0 &&
        <div className="panel-top">

          <div className='panel-top-row'>
            <a>Select by Event: </a>
            <Dropdown id="event-picker">
              <Dropdown.Toggle disabled={this.state.disabled || this.props.isSensorSelected}
                style={{ padding: "0px 5px 0px 5px" }}>
                {this.state.selected_event || 'Select'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {this.state.event_list.map((event) =>
                  <Dropdown.Item
                    key={event} eventKey={event} value={event}
                    onClick={this.onEventSelection}>
                    {event}
                  </Dropdown.Item>
                )
                }
              </Dropdown.Menu>
            </Dropdown>

            <button className="btn-icon" onClick={() => { this.setState({ stepsEnabled: true }) }}><i className="material-icons">help_outline</i> </button>
          </div>

          <div className='panel-top-row'>
            <a>&nbsp; Date:</a>
            <Dropdown id="date-picker">
              <Dropdown.Toggle disabled={this.state.disabled || this.state.selected_event === '' || this.props.isSensorSelected}
                style={{ padding: "0px 5px 0px 5px" }}>
                {this.state.selected_date || 'Latest'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {date_list.map((date) =>
                  <Dropdown.Item
                    key={date} eventKey={date} value={date}
                    onClick={this.onDateSelection}>
                    {date}
                  </Dropdown.Item>
                )
                }
              </Dropdown.Menu>
            </Dropdown>

            <a>&nbsp; Time:</a>
            <Dropdown id="time-picker">
              <Dropdown.Toggle disabled={this.state.disabled || this.state.selected_date === '' || this.props.isSensorSelected}
                style={{ padding: "0px 5px 0px 5px" }}>
                {this.state.selected_time || 'Latest'}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ maxHeight: "500px", overflow: "scroll" }}>
                {time_list.map((time) =>
                  <Dropdown.Item
                    key={time} eventKey={time} value={time}
                    onClick={this.onTimeSelection}>
                    {time}
                  </Dropdown.Item>
                )
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>

        </div>}

      <Steps
        enabled={this.state.stepsEnabled}
        steps={[
          {
            intro: '<h5 align="center">Past estimates</h5><p>To view inundation estimates in the past, select an event, then a date and time. All data displayed in the portal -- including the map, water level charts, and bridge events -- will be shown for the selected date and time.</p>',
          }
        ]}
        options={{
          doneLabel: 'Done',
          showProgress: false,
          disableInteraction: true,
        }}
        initialStep={0}
        onExit={() => { this.setState({ stepsEnabled: false }) }}
      />
    </>)
  }
}

export default withRouter(PanelTop);



// let fruits = [
//   { label: "Apple", value: "🍎" },
//   { label: "Banana", value: "🍌" },
//   { label: "Orange", value: "🍊" }
// ]
// <select id="datetime-picker" onChange={this.onDatetimeSelection}>
//           <option value={this.props.datetime_utc || 'Select Datetime'}>{this.props.datetime_utc || 'Select Datetime'}</option>
//           {this.state.datetime_list.map((datetime) => <option key={datetime} value={datetime}>{datetime}</option>)}
//           {/* {fruits.map((fruit) => <option key={fruit.label} value={fruit.value}>{fruit.label}</option>)} */}
//         </select>