export const BASE_URL = 'https://cema-portal-backend.rnoc.gatech.edu'
export const LAYERS = BASE_URL + '/v1/<datetime>/layers';
export const SENSORS = BASE_URL + '/v1/<datetime>/sensors';
export const BRIDGES = BASE_URL + '/v1/<datetime>/bridges';
export const INUNDATION_CACHE_DATETIME_FOLDER_NAMES = BASE_URL + '/v1/inundation_persistence/datetime_folder_names';
export const INUNDATION_ARCHIVE_DATETIME_FOLDER_NAMES = BASE_URL + '/v1/inundation_persistence/archive/datetime_folder_names';
export const INUNDATION_FLOODING_EVENT = BASE_URL + '/v1/inundation_persistence/flooding_events';

export const INUNDATION_BOUNDARY = BASE_URL + '/v1/inundation/<datetime>/boundary';
export const INUNDATION_LAND = BASE_URL + '/v1/inundation/<datetime>/land';
export const INUNDATION_LAND_LOW_RES = INUNDATION_LAND + '/low_res';
export const INUNDATION_SMALL_WATERWAYS = BASE_URL + '/v1/inundation/<datetime>/small_waterways';
export const INUNDATION_SMALL_WATERWAYS_LOW_RES = INUNDATION_SMALL_WATERWAYS + '/low_res';
export const INUNDATION_LARGE_WATERWAYS = BASE_URL + '/v1/inundation/<datetime>/large_waterways';
export const INUNDATION_LARGE_WATERWAYS_LOW_RES = INUNDATION_LARGE_WATERWAYS + '/low_res';
export const INUNDATION_PREDICTION = BASE_URL + '/v1/inundation/<datetime>/prediction';
