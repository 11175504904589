import axios from 'axios';
import { Line, defaults } from 'react-chartjs-2';
import moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from "react-router";
import queryString from 'query-string';
import {
    Link,
    Route,
} from "react-router-dom";

import './index.css';

import * as API from '../../constants/apiEndpoints';
import BridgeStatus from '../BridgeStatus';
import * as helpers from '../../helpers';

import WidgetSteps from '../WidgetSteps';

class Bridge extends Component {
    render() {
        let lineChart = null;
        if (this.props.sensor !== undefined) {
            let vdatum = this.props.vdatumType;
              let measurements = this.props.sensor.measurements.map(measurement => {
                return {...measurement, 
                  datum : vdatum,
                  water_level: helpers.vdatumConverter(this.props.sensor.vdatum_offsets, measurement.water_level, vdatum)
                  }
              });
              let bridgeElevation = helpers.vdatumConverter(this.props.sensor.vdatum_offsets, this.props.bridge.properties.elevation.elevation, vdatum);

            let sensorData = {
                labels: measurements.map(m => { return moment(m.date).format('MMM D, h:mm A') }),
                datasets: [
                    {
                        label: 'Water level',
                        data: measurements.map(m => { return m.water_level }),
                        backgroundColor: '#202932',
                        borderColor: '#202932',
                        // borderWidth: 2,
                        fill: false,
                    },
                    {
                        label: 'Bridge beam height',
                        data: measurements.map(m => { return bridgeElevation }),
                        backgroundColor: '#999',
                        borderColor: '#999',
                        // borderWidth: 2,
                        fill: false,
                    }
                ]
            }

            if (measurements.length > 0) {
                lineChart = <div className="chart">
                    <span style={{
                        ...this.props.style, fontSize: '1em',
                        display: 'inline-block', width: '100%', textAlign: 'center'
                    }}>Water level</span>
                    <Line
                        data={sensorData}
                        options={{
                            animation: false,
                            elements: {
                                point: {
                                    radius: 0
                                }
                            },
                            hover: {
                                mode: 'nearest',
                                intersect: true
                            },
                            legend: {
                                display: true,
                                position: 'bottom',
                            },
                            maintainAspectRatio: false,
                            responsive: true,
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        lineWidth: 0
                                    },
                                    ticks: {
                                        autoSkip: true,
                                        maxTicksLimit: 7,
                                    }
                                }],
                                yAxes: [{
                                    scaleLabel: {
                                        display: true,
                                        labelString: `Feet (${vdatum})`,
                                    }
                                }],
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false,
                                callbacks: {
                                    label: function (tooltipItem, data) {
                                        return `${Number(tooltipItem.yLabel)} ft (${vdatum})`
                                    }
                                }
                            },
                        }}
                    />
                </div>
            }
        }

        if (defaults.global) {
            defaults.global.defaultFontFamily = this.props.style.fontFamily;
            defaults.global.defaultFontStyle = this.props.style.fontStyle;
            defaults.global.defaultFontColor = this.props.style.color;
        }

        if (Object.keys(this.props.bridge).length > 0) {
            return (
                <div className="panel-body">
                    <div className="content">
                        <span style={{
                            ...this.props.style, fontSize: '1.3em', fontWeight: '700',
                            display: 'inline-block', width: '100%', textAlign: 'center'
                        }}>Bridge status</span>
                        <center><BridgeStatus
                            id={this.props.bridge.properties.id}
                            name={this.props.bridge.properties.name}
                            numEvents={this.props.bridge.properties.num_events}
                            events={this.props.bridge.properties.events}
                            showBridgeName={false}
                            isWidget={true}
                        /></center>                        
                        {lineChart}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}


class BridgeWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sensor: null,
            stepsEnabled: false,
        }

        this.setStepsEnabled = this.setStepsEnabled.bind(this);

        this.prevTimeRange = { start: null, end: null };
    }

    async componentDidMount() {
        let layerDataGeoJSON = await helpers.layerDataToGeoJSON('');

        this.setState({
            ...layerDataGeoJSON,
        })
    }

    setStepsEnabled(isEnabled) {
        this.setState({
            stepsEnabled: isEnabled,
        })
    }

    getSensor = async (id, start, end) => {
        let sensor_url = `${API.BRIDGES.replace('/<datetime>', '')}/${id}`;
        if (start !== undefined && end !== undefined) {
            sensor_url = `${sensor_url}?start=${start}&end=${end}`;
        }

        let sensor = await axios.get(sensor_url);
        this.setState({
            sensor: sensor.data,
        });
    }

    getStartEnd = (props, bridge) => {
        let timeRange = helpers.getStartEnd(props);
        if ('start' in timeRange) {
            return timeRange;
        } else {
            const datetime = bridge.properties.events.slice(-1)[0].date;
            return {
                'start': moment(datetime).subtract(1, 'days').format('YYYY-MM-DD'),
                'end': moment(datetime).add(1, 'days').format('YYYY-MM-DD'),
            }
        }
    }

    render() {
        return (
            <Route exact path="/widget/v1/bridges/:id" render={(props) => {
                if (this.state.bridges !== undefined) {
                    let bridge = helpers.getEntity(this.state, 'bridges', props.match.params.id);

                    let timeRange = this.getStartEnd(props, bridge);
                    let hasTimeRange = (Object.keys(timeRange).length > 0);

                    let isSameTimeRange = true;
                    if (hasTimeRange) {
                        isSameTimeRange = this.prevTimeRange.start === timeRange.start && this.prevTimeRange.end === timeRange.end
                    }
                    this.prevTimeRange = timeRange;

                    // if `start` and `end` are present in the query params, and
                    // if `start` and `end` are different from the previous request, or the selected bridge itself is different from the previous request,
                    // then make a request for the bridge's associated sensor data between `start` and `end`
                    let sensor = null;
                    if (hasTimeRange && isSameTimeRange) {
                        sensor = this.state.sensor;
                    }

                    if (!isSameTimeRange) {
                        this.getSensor(bridge.properties.sensor_id, timeRange.start, timeRange.end);
                    }

                    const queryParams = queryString.parse(props.location.search);
                    const style = {
                        fontFamily: queryParams.fontFamily || 'sans-serif',
                        fontStyle: queryParams.fontStyle || 'normal',
                        color: queryParams.fontColor || 'black',
                    }
                    const backgroundColor = queryParams.backgroundColor || 'white';
                    let vdatum = (queryParams.vdatum || 'MLLW').toUpperCase()
                    if (vdatum === 'NAVD88'){
                        vdatum = 'NAVD 88';
                    }
                    if (!['NAVD 88','MLLW', 'MHHW'].includes(vdatum)){
                        vdatum = 'MLLW';
                    }
                    if (sensor !== null) {
                        let moments = sensor.measurements.map(m => moment(m.date));
                        let latest_measurement_date = moment.max(moments);
                        let heading = bridge.properties.name;

                        return (<div style={{ backgroundColor: backgroundColor }}>
                            {helpers.insideIframe() && <Link to={`/bridges/${props.match.params.id}?layers=bridges`} target="_blank">
                                <p className='label'>Open in CEMA portal <i className="material-icons">open_in_new</i></p>
                            </Link>}

                            {!helpers.insideIframe() && <p className="btn-icon" onClick={() => { this.setState({ stepsEnabled: true }) }}>
                                {'How to use as a widget'}<i className="material-icons">help_outline</i> </p>}

                            <span style={{
                                ...style, fontSize: '1.5em', fontWeight: '700',
                                display: 'inline-block', width: '100%', textAlign: 'center'
                            }}>{heading}</span>
                            <p style={style}>Last updated {latest_measurement_date.panelFromNow()}</p>

                            <Bridge bridge={bridge} sensor={sensor} style={style} vdatumType={vdatum}/>

                            <WidgetSteps enabled={this.state.stepsEnabled} setStepsEnabled={this.setStepsEnabled} />
                        </div>)
                    } else {
                        return (<div style={{ backgroundColor: backgroundColor }}>
                            <Link to={`/bridges/${props.match.params.id}?layers=bridges`} target="_blank">
                                <p className='label'>Open in CEMA portal <i className="material-icons">open_in_new</i></p>
                            </Link>

                            <span style={{
                                ...style, fontSize: '1.5em', fontWeight: '700',
                                display: 'inline-block', width: '100%', textAlign: 'center'
                            }}>{bridge.properties.name}</span>

                            <Bridge bridge={bridge} style={style} vdatumType={vdatum}/>
                        </div>)
                    }
                } else {
                    return null;
                }
            }} />
        )
    }
}

export default withRouter(BridgeWidget);
