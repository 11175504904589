import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';

import './index.css';

import BridgeStatus from '../BridgeStatus';


class Bridges extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seeAllBridges: false,
      bridgeStatusesWithEvents: [],
      bridgeStatusesWithoutEvents: [],
    }    
  }

  setStatuses = () => {
    let bridgeStatusesWithEvents = [];
    let bridgeStatusesWithoutEvents = [];

    let sortedFeatures = this.props.bridges.features.sort((a, b) => (a.properties.num_events < b.properties.num_events) ? 1 : -1)
    for (let i = 0; i < sortedFeatures.length; i += 1) {
      let item = sortedFeatures[i];

      let events = item.properties.events;
      if (events.length === 0) {
        continue;
      }

      let bridgeID = item.properties.id;
      let bridgeName = item.properties.name;
      let numEvents = item.properties.num_events;

      let bridgeStatus = <BridgeStatus 
        key={i}
        id={bridgeID}
        name={bridgeName}
        numEvents={numEvents}
        events={events}
        showBridgeName={true}
      />      

      if (numEvents > 0) {
        bridgeStatusesWithEvents.push(bridgeStatus);
      } else {
        bridgeStatusesWithoutEvents.push(bridgeStatus);
      }
    }

    this.setState({
      bridgeStatusesWithEvents: bridgeStatusesWithEvents,
      bridgeStatusesWithoutEvents: bridgeStatusesWithoutEvents,
    });     
  }

  componentDidMount() {
    if (this.props.bridges) {
      this.setStatuses();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bridges !== this.props.bridges) {
      this.setStatuses()
    }
  }

  render() {
    if (this.state.seeAllBridges) {
      return (
        <div className="panel-body">
          <div className="bridge-status content">
            <h2>Bridge status</h2>
            {this.state.bridgeStatusesWithEvents}
            {this.state.bridgeStatusesWithoutEvents}
            <Button 
              variant="link"
              onClick={() => {
                this.setState({seeAllBridges: false})
              }}
            >See less</Button>
          </div>
        </div>
      );  
    } else {
      return (
        <div className="panel-body">
          <div className="bridge-status content">
            <h2>Bridge status</h2>
            {this.state.bridgeStatusesWithEvents}
            
            <Button 
              variant="link"
              onClick={() => {
                this.setState({seeAllBridges: true})
              }}
            >See all bridges</Button>
          </div>
        </div>
      );  
    }    
  }
}

export default Bridges
