import axios from 'axios';
import moment from 'moment';
import queryString from 'query-string';

import * as API from './constants/apiEndpoints';
import { LAYERS } from './constants/layers';


String.prototype.toTitleCase = function () {
  return this.charAt(0).toUpperCase() + this.substr(1).toLowerCase();
};

moment.fn.panelFromNow = function (a) {
  // if more than 2 days ago
  if (Math.abs(moment().diff(this,'days')) > 2) {
    // "Last updated on Jul 28 at 1:45 PM"
    return 'on ' + this.format("MMM D YYYY") + ' at ' + this.format("h:mm A");
  } else {
    // "Last updated 1 hour ago at 12:45 PM"
    let s = this.fromNow(a);
    return s + ' at ' + this.format('h:mm A');
  }
}

moment.fn.mapFromNow = function (a) {
  // if more than 2 days ago
  if (Math.abs(moment().diff(this,'days')) > 2) {
    // "Last updated on Jul 28 at 1:45 PM"
    return this.format("MMM D YYYY") + ' at ' + this.format("h:mm A");
  } else {
    // "Last updated 1 hour ago at 12:45 PM"
    let s = this.fromNow(a);
    return s.toTitleCase() + ' at ' + this.format('h:mm A');
  }
}

export async function layerDataToGeoJSON(datetimeUtc) {
  let layersGeoJSON = {}
  for (let i = 0; i < LAYERS.length; i += 1) {
    let layer = LAYERS[i];
    layersGeoJSON[layer] = {
      type: 'FeatureCollection',
      features: [],
    };
  }

  const datetimeUrlPath = datetimeUtc ? `/${datetimeUtc}` : '';

  // bridges
  let bridges = await axios.get(API.BRIDGES.replace('/<datetime>', datetimeUrlPath));
  
  for (let i = 0; i < bridges.data.length; i += 1) {
    let bridge = bridges.data[i];
    let feature = {
      type: 'Feature',
      properties: { ...bridge },
      geometry: {
        type: 'Point',
        coordinates: [bridge.lng, bridge.lat],
      }
    }

    layersGeoJSON['bridges'].features.push(feature);
  }
  
  // sensors
  let sensors = await axios.get(API.SENSORS.replace('/<datetime>', datetimeUrlPath));
  
  for (let i = 0; i < sensors.data.length; i += 1) {
    let sensor = sensors.data[i];
    let feature = {
      type: 'Feature',
      properties: { ...sensor },
      geometry: {
        type: 'Point',
        coordinates: [sensor.lng, sensor.lat],
      }
    }

    layersGeoJSON['sensors'].features.push(feature);
  }

  //layers
  let layers = await axios.get(API.LAYERS.replace('/<datetime>', datetimeUrlPath));
  layersGeoJSON['map_updated_at'] = layers.data[0].updated_at;

  return layersGeoJSON;
}


export function getLayers(props) {
  // get `layers` from query params
  let queryParams = queryString.parse(props.location.search);

  let layers = [];
  if ('layers' in queryParams) {
    let params = queryParams.layers.split(',');
    for (let i = 0; i < params.length; i += 1) {
      let param = params[i];
      if (LAYERS.includes(param)) {
        layers = [param];
      }
    }
  }

  return layers;    
}

export function getStartEnd(props) {
  // get `start` and `end` from query params
  let queryParams = queryString.parse(props.location.search);

  let ret = {};
  if (('start' in queryParams) && ('end' in queryParams)) {
    let isValid = (moment(queryParams['start'], 'YYYY-MM-DD').format() !== 'Invalid date') && (moment(queryParams['end'], 'YYYY-MM-DD').format() !== 'Invalid date');
    if (isValid) {
      ret = queryParams;
    }
  }

  return ret;    
}

export function getEntity(props, layer, entityID) {
  let entityGeoJSON = {};

  let _id = parseInt(entityID);
  if (props[layer] !== undefined && !isNaN(_id)) {
    for (let i = 0; i < props[layer].features.length; i += 1) {
      if (_id === props[layer].features[i].properties.id) {
        entityGeoJSON = props[layer].features[i];
        break;
      }
    }
  }

  return entityGeoJSON
}

export function insideIframe() {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}

export function vdatumConverter(vdatumOffsets, water_level, vdatum){
  // convert water_level in NAVD 88 to MHHW/MLLW
  if (vdatum === 'NAVD 88'){
      return parseFloat(water_level.toFixed(2));
  }
  else {
    if (vdatumOffsets === null || vdatumOffsets === undefined || !(vdatum in vdatumOffsets)){
      // Default vdatumOffsets
      vdatumOffsets = {MLLW:3.903,MHHW:-3.494} 
    }
      return parseFloat((water_level + vdatumOffsets[vdatum]).toFixed(2));
  }
}
