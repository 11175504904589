
import React, { Component } from 'react';
import ReactSlider from 'react-slider';
import * as helpers from '../../helpers';

import './index.css';

class FloodSlider extends Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (<div className="flood-slider">
            <p className="slider-text">Select water level (in ft {this.props.vdatum}) to simulate flooding in area around the sensor</p>
            <ReactSlider
                className="horizontal-slider"
                marks
                renderMark={(props) => {
                    props.style['color'] = this.props.selectedWaterLevel > props.key ? "white" : "black";
                    const waterLevel = helpers.vdatumConverter(this.props.vdatumOffsets, this.props.selectedSensorWaterLevel + props.key, this.props.vdatum)
                    return <div {...props}>{waterLevel.toFixed(1)}</div>
                }}
                markClassName="horizontal-slider-mark"
                min={0}
                max={15}
                defaultValue={this.props.selectedWaterLevel || 0}
                thumbClassName="horizontal-slider-thumb"
                trackClassName="horizontal-slider-track"
                renderThumb={(props, state) => {
                    const waterLevel = helpers.vdatumConverter(this.props.vdatumOffsets, this.props.selectedSensorWaterLevel + state.valueNow, this.props.vdatum)
                    return <div {...props}>{waterLevel.toFixed(1)}</div>
                }}
                onChange={(value, index) => { this.props.setSelectedWaterLevel(value) }}
            />
        </div>)

    }
}

export default FloodSlider