import React, { Component } from "react";
import { Steps } from 'intro.js-react';

class WidgetSteps extends Component {
    render() {
        const widgetUrlCustomLook = `${window.location.origin + window.location.pathname}?fontColor=black&backgroundColor=pink&fontFamily=monospace`

        const steps = [
            {
                intro: `<h5 align="center">Use as a widget</h5>
                <p>Apart from accessing this URL in the browser, 
                you can use it as a widget in your website using iframe.</p>`,
            },
            {
                intro: `<h5 align="center">Copy the URL</h5>
                <p>Copy the URL for this page <mark style="color:brown; overflow-wrap: break-word;">
                ${window.location}</mark> <br> Same as what you see in the browser tab above.</p>`,
            },
            {
                intro: `<h5 align="center">Embed using iframe</h5>
                <p>Use the copied URL with iframe to integrate in your website.
                Depending on the location you want to show the widget, place this in your code:</p> 
                <code style="color:brown;">
                    &lt;iframe src="${window.location}" style="width: 450px; height: 600px;"&gt;
                    &lt;/iframe&gt;
                </code>`
            },
            {
                intro: `<h5 align="center">Customize the look</h5> 
                <p>The widget can be customized by passing style options as query paramters in the URL.
                The supported query parameters are: <mark style="color: brown;">backgroundColor, 
                fontColor, fontFamily, fontStyle</mark>. <br>
                Values for these parameters can be any value supported by CSS. <br> <br>
                Example: <a href="${widgetUrlCustomLook}" target="_blank">
                 <mark style="color:brown; overflow-wrap: break-word;">
                 ${widgetUrlCustomLook} </mark> </a> 
                 </p>`
            }
        ];

        return (
            <Steps
                enabled={this.props.enabled}
                steps={steps}
                options={{
                    doneLabel: 'Done',
                    hideNext: false,
                    nextToDone: true,
                    showProgress: true,
                }}
                initialStep={0}
                onExit={() => { this.props.setStepsEnabled(false) }}
            />
        )
    }
}

export default WidgetSteps