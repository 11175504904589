
import { ScatterplotLayer } from 'deck.gl';
import {
    INUNDATION_BOUNDARY,
    INUNDATION_LAND,
    INUNDATION_LAND_LOW_RES,
    INUNDATION_SMALL_WATERWAYS,
    INUNDATION_SMALL_WATERWAYS_LOW_RES,
    INUNDATION_LARGE_WATERWAYS,
    INUNDATION_LARGE_WATERWAYS_LOW_RES,
    INUNDATION_PREDICTION,
} from '../../constants/apiEndpoints';
import {
    NUM_INUNDATION_BOUNDARY_LAYERS,
    NUM_INUNDATION_LAYERS
} from '../../constants/index'

// ========================================================================c
// Boundary Layer
export function getInundationBoundaryLayer(activeInundationLayer, lowResolution, isSensorSelected, opacity, datetimeUrlPath) {
    const inundationLayers_Boundary_Urls = []
    for (let i = 0; i < NUM_INUNDATION_BOUNDARY_LAYERS; i += 1) {
        let url = `${INUNDATION_BOUNDARY.replace('/<datetime>', datetimeUrlPath)}/${i}`
        inundationLayers_Boundary_Urls.push(url);
    }
    return inundationLayers_Boundary_Urls.map((chunkUrl, chunkIndex) => new ScatterplotLayer({
        id: `inundationBoundary-${chunkIndex}`,
        data: chunkUrl,
        stroked: false,
        radiusScale: 20,
        getPosition: d => d.CD, //COORDINATES
        getFillColor: d => [72, 72, 72],
        visible: isSensorSelected ? false : true,
    }));
};

// ------------------------------------------------------------------------
// Land Layer
export function getInundationLandLayer(activeInundationLayer, lowResolution, isSensorSelected, opacity, datetimeUrlPath) {
    const inundationLayers_Land_Urls = []
    for (let i = 0; i < NUM_INUNDATION_LAYERS; i += 1) {
        const URL = lowResolution ? INUNDATION_LAND_LOW_RES : INUNDATION_LAND
        let url = `${URL.replace('/<datetime>', datetimeUrlPath)}/${i}`
        inundationLayers_Land_Urls.push(url);
    }
    return inundationLayers_Land_Urls.map((chunkUrl, chunkIndex) => new ScatterplotLayer({
        id: `inundationLand-${chunkIndex}-${opacity}`,
        data: chunkUrl,
        stroked: false,
        radiusScale: lowResolution ? 20 : 10,
        getPosition: d => d.CD, //COORDINATES
        getFillColor: d => {
            return inundationDepthToColor(d.ID, opacity);
        },
        pickable: true,
        visible: isSensorSelected ? false : true,
    }));
};

// ------------------------------------------------------------------------
// Small waterways
export function getInundationSmallWaterwaysLayer(activeInundationLayer, lowResolution, isSensorSelected, opacity, datetimeUrlPath) {
    const inundationLayers_LandSmall_Urls = []
    for (let i = 0; i < NUM_INUNDATION_LAYERS; i += 1) {
        const URL = lowResolution ? INUNDATION_SMALL_WATERWAYS_LOW_RES : INUNDATION_SMALL_WATERWAYS;
        let url = `${URL.replace('/<datetime>', datetimeUrlPath)}/${i}`
        inundationLayers_LandSmall_Urls.push(url);
    }
    return inundationLayers_LandSmall_Urls.map((chunkUrl, chunkIndex) => new ScatterplotLayer({
        id: `inundationSmallWaterways-${chunkIndex}-${opacity}`,
        data: chunkUrl,
        stroked: false,
        radiusScale: lowResolution ? 20 : 10,
        getPosition: d => d.CD, //COORDINATES
        getFillColor: d => {
            return inundationDepthToColor(d.ID, opacity);
        },
        pickable: true,
        visible: (activeInundationLayer !== 'land' && !isSensorSelected) ? true : false,
    }));
};

// ------------------------------------------------------------------------
// Large waterways
export function getInundationLargeWaterwaysLayer(activeInundationLayer, lowResolution, isSensorSelected, opacity, datetimeUrlPath) {
    let inundationLayers_LandSmallLarge_Urls = []
    for (let i = 0; i < NUM_INUNDATION_LAYERS; i += 1) {
        const URL = lowResolution ? INUNDATION_LARGE_WATERWAYS_LOW_RES : INUNDATION_LARGE_WATERWAYS;
        let url = `${URL.replace('/<datetime>', datetimeUrlPath)}/${i}`
        inundationLayers_LandSmallLarge_Urls.push(url);
    }
    return inundationLayers_LandSmallLarge_Urls.map((chunkUrl, chunkIndex) => new ScatterplotLayer({
        id: `inundationLargeWaterways-${chunkIndex}-${opacity}`,
        data: chunkUrl,
        stroked: false,
        radiusScale: lowResolution ? 20 : 10,
        getPosition: d => d.CD, //COORDINATES
        getFillColor: d => {
            return inundationDepthToColor(d.ID, opacity);
        },
        pickable: true,
        visible: (activeInundationLayer === 'land-small-large') && !isSensorSelected ? true : false,
    }));
};

// ------------------------------------------------------------------------
// Flood prediction Layer
export function getInundationPredictionLayer(isSensorSelected, selectedSensorId, selectedWaterLevel, opacity, datetimeUrlPath) {
    const inundationPredictionUrl = `${INUNDATION_PREDICTION.replace('/<datetime>', datetimeUrlPath)}/${selectedSensorId}`
    return new ScatterplotLayer({
        id: `inundationLand-${selectedSensorId}-${selectedWaterLevel}-${opacity}`,
        data: inundationPredictionUrl,
        stroked: false,
        radiusScale: 10,
        getPosition: d => d.CD,
        getFillColor: d => {
            return inundationDepthToColor(getInundationDepth(d.ID, selectedWaterLevel, isSensorSelected), opacity);
        },
        pickable: true,
        visible: isSensorSelected ? true : false,
    });
};

export function getInundationDepth(actualDepth, selectedWaterLevel, isSensorSelected) {
    // // Convert from degrees to radians.
    // const lon1 = coordinates[0] * Math.PI / 180;
    // const lon2 = sensorCoordinates[0] * Math.PI / 180;
    // const lat1 = coordinates[1] * Math.PI / 180;
    // const lat2 = sensorCoordinates[1] * Math.PI / 180;

    // // Haversine formula
    // let dlon = lon2 - lon1;
    // let dlat = lat2 - lat1;
    // let a = Math.pow(Math.sin(dlat / 2), 2)
    //     + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

    // // 3956 is radius of earth in miles. Use 6371 for kilometers.
    // const distanceFromSensor = (2 * Math.asin(Math.sqrt(a)) * 3956);

    // if (distanceFromSensor > 1) {
    //     if (selectedWaterLevel > 0) {
    //         return -1; // don't show inundation outside of 1 mile radius for flood simulation
    //     }
    //     return actualDepth;
    // }
    if (isSensorSelected) {
        return actualDepth + selectedWaterLevel;
    }
    return actualDepth;
}

function inundationDepthToColor(inundation_depth, opacity) {
    // from https://colorbrewer2.org/#type=sequential&scheme=Blues&n=5

    if (inundation_depth < 0) {
        return [0, 0, 0, 0]
    }

    // 0-6in
    if (inundation_depth <= 0.5) {
        return [239, 243, 255, opacity*255]
    }

    // 6-12 in
    else if (inundation_depth > 0.5 && inundation_depth <= 1) {
        return [189, 215, 231, opacity*255]
    }

    // 12-18 in
    else if (inundation_depth > 1 && inundation_depth <= 1.5) {
        return [107, 174, 214, opacity*255]
    }

    // 18-24 in
    else if (inundation_depth > 1.5 && inundation_depth <= 2) {
        return [49, 130, 189, opacity*255]
    }

    // 24+ in
    else if (inundation_depth > 2) {
        return [8, 81, 156, opacity*255]
    }
}