import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from "react-router";
import {
  Switch,
  Route,
} from "react-router-dom";

import './index.css';

import * as API from '../../constants/apiEndpoints';
import * as helpers from '../../helpers';
import FloodSlider from '../FloodSlider';

class Sensor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    }
  }

  handleShowModal = () => {
    this.setState({ showModal: true })
  }

  handleCloseModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    let vdatum = this.props.vdatumType;
    let measurements = this.props.sensor.measurements.map(measurement => {
      return {
        ...measurement,
        datum: vdatum,
        water_level: helpers.vdatumConverter(this.props.sensor.vdatum_offsets, measurement.water_level, vdatum)
      }
    });
    let sensorData = {
      labels: measurements.map(m => { return moment(m.date).format('MMM D, h:mm A') }),
      datasets: [
        {
          label: 'Water level',
          data: measurements.map(m => { return m.water_level }),
          backgroundColor: '#202932',
          borderColor: '#202932',
          // borderWidth: 2,
          fill: false,
        },
      ]
    }

    // add risk rating if available
    let riskRatingsJsx = (<></>)
    if ('risk_ratings' in this.props.sensor) {
      let riskRatingLines = [
        {
          label: 'Minor threshold',
          data: measurements.map(m => { return helpers.vdatumConverter(this.props.sensor.vdatum_offsets, this.props.sensor['risk_ratings']['minor'], vdatum) }),
          backgroundColor: '#FF9900',
          borderColor: '#FF9900',
          // borderWidth: 2,
          fill: false,
        },
        {
          label: 'Moderate threshold',
          data: measurements.map(m => { return helpers.vdatumConverter(this.props.sensor.vdatum_offsets, this.props.sensor['risk_ratings']['moderate'], vdatum) }),
          backgroundColor: '#FF0102',
          borderColor: '#FF0102',
          // borderWidth: 2,
          fill: false,
        },
        {
          label: 'Major threshold',
          data: measurements.map(m => { return helpers.vdatumConverter(this.props.sensor.vdatum_offsets, this.props.sensor['risk_ratings']['major'], vdatum) }),
          backgroundColor: '#CC33FF',
          borderColor: '#CC33FF',
          // borderWidth: 2,
          fill: false,
        }
      ]

      sensorData.datasets.push(...riskRatingLines)

      riskRatingsJsx = (
        <div className="group risk-ratings">
          <p className="label">Risk ratings</p>
          <div>
            <ul>
              <li><span className="color" style={{ backgroundColor: '#FF9900' }}></span> <span className="value">Minor:</span> <span className="value">{helpers.vdatumConverter(this.props.sensor.vdatum_offsets, this.props.sensor['risk_ratings']['minor'], vdatum)}</span> <span>ft</span> <span className="datum">({vdatum})</span></li>
              <li><span className="color" style={{ backgroundColor: '#FF0102' }}></span> <span className="value">Moderate:</span> <span className="value">{helpers.vdatumConverter(this.props.sensor.vdatum_offsets, this.props.sensor['risk_ratings']['moderate'], vdatum)}</span> <span>ft</span> <span className="datum">({vdatum})</span></li>
              <li><span className="color" style={{ backgroundColor: '#CC33FF' }}></span> <span className="value">Major:</span> <span className="value">{helpers.vdatumConverter(this.props.sensor.vdatum_offsets, this.props.sensor['risk_ratings']['major'], vdatum)}</span> <span>ft</span> <span className="datum">({vdatum})</span></li>
            </ul>
          </div>
        </div>
      )
    }

    if (measurements.length === 0) {
      return (
        <div className="content">
          <h2>Water level</h2>
          <div className="group">
            <p className="label">Water level</p>
            <p>No recent data</p>
          </div>
          {riskRatingsJsx}
        </div>
      );
    } else {
      let latestMeasurement = measurements[measurements.length - 1]

      let minY = 10;
      for (let i = 0; i < measurements.length; i += 1) {
        let waterLevel = measurements[i].water_level;
        if (waterLevel < minY) {
          minY = waterLevel;
        }
      }
      minY -= 1;
      if (minY < -10) {
        minY = -10;
      }

      let lineChart = (
        <Line
          data={sensorData}
          options={{
            animation: false,
            elements: {
              point: {
                radius: 1.5
              }
            },
            hover: {
              mode: 'nearest',
              intersect: true
            },
            legend: {
              display: false,
            },
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              xAxes: [{
                gridLines: {
                  lineWidth: 0
                },
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 12,
                }
              }],
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: `Feet (${vdatum})`,
                },
                ticks: {
                  min: minY,
                }
              }],
            },
            tooltips: {
              enabled: true,
              displayColors: false,
              callbacks: {
                title: function (tooltipItems, data) {
                  let datasetLabel = data.datasets[tooltipItems[0].datasetIndex].label
                  let xLabel = tooltipItems[0].xLabel
                  let yValue = tooltipItems[0].yLabel
                  if (datasetLabel === 'Water level') {
                    return xLabel
                  } else {
                    return `${datasetLabel}: ${yValue} ft (${vdatum})`
                  }
                },
                label: function (tooltipItem, data) {
                  let datasetLabel = data.datasets[tooltipItem.datasetIndex].label
                  let yValue = tooltipItem.yLabel
                  if (datasetLabel === 'Water level') {
                    let ret = [`${datasetLabel}: ${yValue} ft (${vdatum})`]
                    for (let i = 1; i < data.datasets.length; i += 1) {
                      let value = data.datasets[i].data[0];
                      let label = data.datasets[i].label;
                      let distance = value - yValue;
                      if (distance < 0) {
                        continue
                      } else {
                        let str = `* Distance from ${label}: ${distance.toFixed(1)} ft (${vdatum})`
                        ret.push(str)
                      }
                    }
                    return ret;
                  } else {
                    return;
                  }
                },
              }
            }
          }}
        />
      )
      return (
        <>
          <div className="content">
            <h2>Water level</h2>
            <div className="group" style={{ justifyContent: 'space-between' }}>
              <div className="group">
                <p className="label">Water level</p>
                <p><span className="value">{latestMeasurement.water_level} {latestMeasurement.unit}</span> <span className="confidence-interval">&plusmn; {latestMeasurement.confidence_interval} {latestMeasurement.unit}</span> <span className="datum">({latestMeasurement.datum})</span></p>
              </div>
              <button title="Full screen chart" className="btn-icon" onClick={this.handleShowModal}><i className="material-icons">fullscreen</i> </button>
            </div>
            <div className="chart group">
              {lineChart}
            </div>
            {riskRatingsJsx}
          </div>
          <Modal
            animation={false}
            centered
            contentClassName="modal-60h"
            onHide={this.handleCloseModal}
            show={this.state.showModal}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>Water level</Modal.Title>
            </Modal.Header>
            <Modal.Body>{lineChart}</Modal.Body>
          </Modal>
        </>
      );
    }
  }
}

class PanelBottom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sensor: null,
      tab: "current",
    }

    this.prevTimeRange = { start: null, end: null };
    this.prevBridge = { properties: { id: -1 } };
  }

  componentDidMount() {
    this.getSensor(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.match.params.id !== this.props.match.params.id){
      this.setState({
        sensor: null,
        tab: "current",
      });
      this.getSensor(this.props.match.params.id);
    }
  }

  getSensor = async (id, start, end) => {
    const datetimeUrlPath = this.props.datetime_utc ? `/${this.props.datetime_utc}` : '';
    let sensor_url = `${API.SENSORS.replace('/<datetime>', datetimeUrlPath)}/${id}`;
    if (start !== undefined && end !== undefined) {
      sensor_url = `${sensor_url}?start=${start}&end=${end}`;
    }

    let sensor = await axios.get(sensor_url);
    this.setState({
      sensor: sensor.data,
    });
  }


  render() {
    let that = this;
    if(that.state.sensor === null){
      return null;
    }
    else {
      return (<>
        <div className='tab-selection'>
          <button className={this.state.tab === "current" ? 'tab-selection-button-active'
            : 'tab-selection-button'} onClick={() => { this.setState({ tab: 'current' }) }}>Observations</button>
          {that.props.selectedSensorWaterLevel !== undefined && <button className={this.state.tab === "scenario" ? 'tab-selection-button-active'
            : 'tab-selection-button'} onClick={() => { this.setState({ tab: 'scenario' }) }}>Scenario</button>}
        </div>
        <div className="panel-bottom">
          {this.state.tab === "current" &&
                 <Sensor sensor={that.state.sensor} vdatumType={that.props.vdatumType} />}
          {this.state.tab === "scenario"  && that.props.selectedSensorWaterLevel !== undefined && <FloodSlider
            selectedWaterLevel={that.props.selectedWaterLevel}
            selectedSensorWaterLevel={that.props.selectedSensorWaterLevel}
            setSelectedWaterLevel={that.props.setSelectedWaterLevel}
            vdatum={that.props.vdatumType}
            vdatumOffsets={that.props.vdatumOffsets.current}
          />}
        </div>
      </>)
    }
  }
}

export default withRouter(PanelBottom);
