import axios from 'axios';
import { Line, defaults } from 'react-chartjs-2';
import moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from "react-router";
import {
    Link,
    Route,
} from "react-router-dom";
import queryString from 'query-string';

import './index.css';

import * as API from '../../constants/apiEndpoints';
import * as helpers from '../../helpers';

import WidgetSteps from '../WidgetSteps';


class Sensor extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        let vdatum = this.props.vdatumType;
        let measurements = this.props.sensor.measurements.map(measurement => {
            return {...measurement, 
              datum : vdatum,
              water_level: helpers.vdatumConverter(this.props.sensor.vdatum_offsets, measurement.water_level, vdatum)
              }
          });
        let sensorData = {
            labels: measurements.map(m => { return moment(m.date).format('MMM D, h:mm A') }),
            datasets: [
                {
                    label: 'Water level',
                    data: measurements.map(m => { return m.water_level }),
                    backgroundColor: '#202932',
                    borderColor: '#202932',
                    // borderWidth: 2,
                    fill: false,
                },
            ]
        }

        // add risk rating if available
        let riskRatingsJsx = (<></>)
        if ('risk_ratings' in this.props.sensor) {
            let riskRatingLines = [
              {
                label: 'Minor threshold',
                data: measurements.map(m => { return helpers.vdatumConverter(this.props.sensor.vdatum_offsets, this.props.sensor['risk_ratings']['minor'], vdatum) }),
                backgroundColor: '#FF9900',
                borderColor: '#FF9900',
                // borderWidth: 2,
                fill: false,
              },
              {
                label: 'Moderate threshold',
                data: measurements.map(m => { return helpers.vdatumConverter(this.props.sensor.vdatum_offsets, this.props.sensor['risk_ratings']['moderate'], vdatum) }),
                backgroundColor: '#FF0102',
                borderColor: '#FF0102',
                // borderWidth: 2,
                fill: false,
              },
              {
                label: 'Major threshold',
                data: measurements.map(m => { return helpers.vdatumConverter(this.props.sensor.vdatum_offsets, this.props.sensor['risk_ratings']['major'], vdatum) }),
                backgroundColor: '#CC33FF',
                borderColor: '#CC33FF',
                // borderWidth: 2,
                fill: false,
              }
            ]

            sensorData.datasets.push(...riskRatingLines)

            riskRatingsJsx = (
                <div className="group risk-ratings">
                    <p className="label">Risk ratings</p>
                    <div>
                        <ul>
                            <li><span className="color" style={{ backgroundColor: '#FF9900' }}></span> <span style={this.props.style}><span className="value" >Minor:</span> <span className="value">{helpers.vdatumConverter(this.props.sensor.vdatum_offsets, this.props.sensor['risk_ratings']['minor'], vdatum)}</span> <span>ft</span> <span className="datum">({vdatum})</span></span></li>
                            <li><span className="color" style={{ backgroundColor: '#FF0102' }}></span> <span style={this.props.style}><span className="value" >Moderate:</span> <span className="value">{helpers.vdatumConverter(this.props.sensor.vdatum_offsets, this.props.sensor['risk_ratings']['moderate'], vdatum)}</span> <span>ft</span> <span className="datum">({vdatum})</span></span></li>
                            <li><span className="color" style={{ backgroundColor: '#CC33FF' }}></span> <span style={this.props.style}><span className="value" >Major:</span> <span className="value">{helpers.vdatumConverter(this.props.sensor.vdatum_offsets, this.props.sensor['risk_ratings']['major'], vdatum)}</span> <span>ft</span> <span className="datum">({vdatum})</span></span></li>
                        </ul>
                    </div>
                </div>
            )
        }

        if (measurements.length === 0) {
            return (
                <div className="panel-body">
                    <div className="content">
                        <div className="group">
                            <p className="label">Water level</p>
                            <p>No recent data</p>
                        </div>
                        {riskRatingsJsx}
                    </div>
                </div>
            );
        } else {
            let latestMeasurement = measurements[measurements.length - 1]

            let minY = 10;
            for (let i = 0; i < measurements.length; i += 1) {
                let waterLevel = measurements[i].water_level;
                if (waterLevel < minY) {
                    minY = waterLevel;
                }
            }
            minY -= 1;
            if (minY < -10) {
                minY = -10;
            }

            if (defaults.global) {
                defaults.global.defaultFontFamily = this.props.style.fontFamily;
                defaults.global.defaultFontStyle = this.props.style.fontStyle;
                defaults.global.defaultFontColor = this.props.style.color;
            }

            let lineChart = (
                <Line
                    data={sensorData}
                    options={{
                        animation: false,
                        elements: {
                            point: {
                                radius: 1.5
                            }
                        },
                        hover: {
                            mode: 'nearest',
                            intersect: true
                        },
                        legend: {
                            display: false,
                        },
                        maintainAspectRatio: false,
                        responsive: true,
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    lineWidth: 0
                                },
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 12,
                                }
                            }],
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: `Feet (${vdatum})`,
                                },
                                ticks: {
                                    min: minY,
                                }
                            }],
                        },
                        tooltips: {
                            enabled: true,
                            displayColors: false,
                            callbacks: {
                                title: function (tooltipItems, data) {
                                    let datasetLabel = data.datasets[tooltipItems[0].datasetIndex].label
                                    let xLabel = tooltipItems[0].xLabel
                                    let yValue = tooltipItems[0].yLabel
                                    if (datasetLabel === 'Water level') {
                                        return xLabel
                                    } else {
                                        return `${datasetLabel}: ${yValue} ft (${vdatum})`
                                    }
                                },
                                label: function (tooltipItem, data) {
                                    let datasetLabel = data.datasets[tooltipItem.datasetIndex].label
                                    let yValue = tooltipItem.yLabel
                                    if (datasetLabel === 'Water level') {
                                        let ret = [`${datasetLabel}: ${yValue} ft (${vdatum})`]
                                        for (let i = 1; i < data.datasets.length; i += 1) {
                                            let value = data.datasets[i].data[0];
                                            let label = data.datasets[i].label;
                                            let distance = value - yValue;
                                            if (distance < 0) {
                                                continue
                                            } else {
                                                let str = `* Distance from ${label}: ${distance.toFixed(1)} ft (${vdatum})`
                                                ret.push(str)
                                            }
                                        }
                                        return ret;
                                    } else {
                                        return;
                                    }
                                },
                            }
                        }
                    }}
                />
            )
            return (
                <div className="panel-body">
                    <div className="content">
                        <div className="group" style={{ justifyContent: 'space-between' }}>
                            <div className="group">
                                <p className="label">Water level</p>
                                <p><span style={this.props.style}><span className="value">{latestMeasurement.water_level} {latestMeasurement.unit}</span> <span className="confidence-interval">&plusmn; {latestMeasurement.confidence_interval} {latestMeasurement.unit}</span> <span className="datum">({latestMeasurement.datum})</span></span></p>
                            </div>
                        </div>
                        <div className="chart group">
                            {lineChart}
                        </div>
                        {riskRatingsJsx}
                    </div>
                </div>
            );
        }
    }
}

class SensorWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sensor: null,
            stepsEnabled: false,
        }

        this.prevTimeRange = { start: null, end: null };
        this.prevBridge = { properties: { id: -1 } };

        this.setStepsEnabled = this.setStepsEnabled.bind(this);
    }

    setStepsEnabled(isEnabled){
        this.setState({
            stepsEnabled: isEnabled,
        })
    }

    getSensor = async (id, start, end) => {
        let sensor_url = `${API.SENSORS.replace('/<datetime>', '')}/${id}`;
        if (start !== undefined && end !== undefined) {
            sensor_url = `${sensor_url}?start=${start}&end=${end}`;
        }

        let sensor = await axios.get(sensor_url);
        this.setState({
            sensor: sensor.data,
        });
    }

    render() {
        return (
            <Route exact path="/widget/v1/sensors/:id" render={(props) => {
                if (this.state.sensor !== null && this.state.sensor.id === parseInt(props.match.params.id)) {
                    let heading = this.state.sensor.description;
                    let moments = this.state.sensor.measurements.map(m => moment(m.date));
                    let latest_measurement_date = moment.max(moments);

                    const queryParams = queryString.parse(props.location.search);
                    const style = {
                        fontFamily: queryParams.fontFamily || 'sans-serif',
                        fontStyle: queryParams.fontStyle || 'normal',
                        color: queryParams.fontColor || 'black',
                    }
                    const backgroundColor = queryParams.backgroundColor || 'white';
                    let vdatum = (queryParams.vdatum || 'MLLW').toUpperCase()
                    if (vdatum === 'NAVD88'){
                        vdatum = 'NAVD 88';
                    }
                    if (!['NAVD 88','MLLW', 'MHHW'].includes(vdatum)){
                        vdatum = 'MLLW';
                    }
                    return (
                        <div style={{ backgroundColor: backgroundColor }}>
                            {helpers.insideIframe() &&  <Link to={`/sensors/${props.match.params.id}?layers=sensors`} target="_blank">
                                <p className='label'>Open in CEMA portal <i className="material-icons">open_in_new</i></p>
                            </Link>}

                            {!helpers.insideIframe() && <p className="btn-icon" onClick={() => { this.setState({ stepsEnabled: true }) }}>
                                    {'How to use as a widget'}<i className="material-icons">help_outline</i> </p>}

                            <span style={{
                                ...style, fontSize: '1.5em', fontWeight: '700',
                                display: 'inline-block', width: '100%', textAlign: 'center'
                            }}>{heading}</span>
                            <p style={style}> Last updated {latest_measurement_date.panelFromNow()}</p>

                            <Sensor sensor={this.state.sensor} style={style} vdatumType={vdatum}/>

                            <WidgetSteps enabled={this.state.stepsEnabled} setStepsEnabled={this.setStepsEnabled}/>
                        </div>);
                } else {
                    this.getSensor(props.match.params.id)
                    return null;
                }
            }} />
        )
    }
}

export default withRouter(SensorWidget);
