import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import './index.css';

import App from './components/App';
import SensorWidget from './components/SensorWidget';
import BridgeWidget from './components/BridgeWidget';
import NotFound from './components/NotFound';
import * as serviceWorker from './serviceWorker';

const routes = (
  <Router>
    <Switch>
      <Route exact path="/bridges/:id" component={App} />
      <Route exact path="/sensors/:id" component={App} />
      <Route exact path="/widget/v1/sensors/:id" component={SensorWidget} />
      <Route exact path="/widget/v1/bridges/:id" component={BridgeWidget} />
      <Route exact path="/" component={App} />
      <Route path = "*" component={NotFound} />
    </Switch>
  </Router>
)

ReactDOM.render(routes, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
