import React, { Component } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import moment from 'moment';
import queryString from 'query-string';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';


class BridgeStatus extends Component {
  render() {
    let numEventsString;
    if (this.props.events.length === 0) {
      numEventsString = 'No recent data';
    } else if (this.props.events.length > 0 && this.props.numEvents === 1) {
      numEventsString = `${this.props.numEvents} event in the last 60 days`;
    } else {
      numEventsString = `${this.props.numEvents} events in the last 60 days`;
    }

    let rects = []
    for (let i = 0; i < this.props.events.length; i += 1) {
      let event = this.props.events[i];

      let datetime = event.date
      let datetimeString = moment(datetime).format('MMM D, h:mm A')

      let distanceFromBridgeFt = event.distance_from_bridge
      let distanceFromBridgeIn = Math.round((distanceFromBridgeFt*12))
      //let datum = event.datum

      let distanceString = `Water within ${distanceFromBridgeFt} ft of bridge`;
      if (distanceFromBridgeFt < 1) {
        distanceString = `Water within ${distanceFromBridgeIn} in of bridge`;
      }

      let thresholdColor = '#0da960';    // green - more than 1ft away from bottom
      if (distanceFromBridgeFt <= 0.5) {
        thresholdColor = '#ff3300';      // red - <= 6in away from bottom
      } else if (distanceFromBridgeFt > 0.5 & distanceFromBridgeFt <= 1) {
        thresholdColor = '#ff3300';      // red - <= 6in away from bottom
      }

      let queryParams = queryString.parse(this.props.location.search, {arrayFormat: 'comma'});

      let prevDay = moment(datetime).subtract(1, 'days').format('YYYY-MM-DD');
      let nextDay = moment(datetime).add(1, 'days').format('YYYY-MM-DD');
      queryParams['start'] = prevDay;
      queryParams['end'] = nextDay;

      let base_url = this.props.isWidget ? '/widget/v1/bridges' :'/bridges';

      let rect = <OverlayTrigger 
        key={i} 
        placement='top' 
        overlay={
          <Tooltip>
            <strong>{datetimeString}</strong><br/>{distanceString}
          </Tooltip>
        }
      >
        <Link to={`${base_url}/${this.props.id}?${queryString.stringify(queryParams, {arrayFormat: 'comma'})}`}>
          <rect key={i} height="34" width="3.5" x={i * 6} y="0" fill={thresholdColor} className="status-bar"></rect>
        </Link>
      </OverlayTrigger>
      
      rects.push(rect);
    }

    let bridgeName = null;
    if (this.props.showBridgeName) {
      bridgeName = <p className="bridge-name">{this.props.name}</p>
    }

    return (

      <div className="status">
        <Link to={`/bridges/${this.props.id}?layers=bridges`}>
          {bridgeName}
          <p className="num-events">{numEventsString}</p>
        </Link>
        <div className="status-chart">
          <svg height="34" viewBox="0 0 370 34">
            {rects}
          </svg>
        </div>
      </div>
    )
  }
}

export default withRouter(BridgeStatus);
