import moment from 'moment';
import React, { Component } from 'react';
import {
  Form
} from 'react-bootstrap';
import { withRouter } from "react-router";
import {
  Link,
  Route,
  Switch,
} from "react-router-dom";

import 'intro.js/introjs.css';
import { Steps } from 'intro.js-react';

import './index.css';

import * as helpers from '../../helpers';
import { LAYERS } from '../../constants/layers';


class PanelHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stepsEnabled: false,
    }
  }

  toggleLayer = (event) => {
    this.props.onLayerChange(event.target.value, event.target.checked)
  }

  render() {
    let that = this

    let heading = 'Smart Sea Level Sensors';

    let layers = helpers.getLayers(this.props)
    let radioButtons = []
    for (let i = 0; i < LAYERS.length; i += 1) {
      let layer = LAYERS[i];
      let radioButton = <Form.Check className={layer} key={i} type="radio" label={layer.toTitleCase()} name="layers" value={layer} onChange={this.toggleLayer} checked={layers.includes(layer)} />
      radioButtons.push(radioButton);
    }


    return (
      <div className="panel-header">
        <Switch>
          <Route exact path="/bridges/:id" render={(props) => {
            if (that.props.bridges !== undefined) {
              let bridge = helpers.getEntity(that.props, 'bridges', props.match.params.id);
              heading = bridge.properties.name;
            }

            return (
              <div>
                <div className="panel-nav">
                  <Link to={`/?layers=${layers.join()}&datetime_utc=${this.props.datetime_utc}`}>
                    <p className='label'><i className="material-icons">arrow_back</i> Bridge</p>
                  </Link>
                  <Link to={`/widget/v1/bridges/${props.match.params.id}?vdatum=${this.props.vdatumType}`} target='_blank'>
                    <p className='label'>Widget URL<i className="material-icons">open_in_new</i></p>
                  </Link>
                  <button className="btn-icon" onClick={() => { this.setState({ stepsEnabled: true }) }}><i className="material-icons">help_outline</i> </button>
                </div>
                <h1 className="title">{heading}</h1>
              </div>
            )
          }} />
          <Route exact path="/sensors/:id" render={(props) => {
            if (that.props.sensors !== undefined) {
              let sensor = helpers.getEntity(that.props, 'sensors', props.match.params.id);
              heading = sensor.properties.description;

              return (
                <div>
                  <div className="panel-nav">
                    <Link to={`/?layers=${layers.join()}&datetime_utc=${this.props.datetime_utc}`} onClick={() => { this.props.setSensorSelected(false) }} >
                      <p className='label'><i className="material-icons">arrow_back</i> Sensor</p>
                    </Link>
                    <Link to={`/widget/v1/sensors/${props.match.params.id}?vdatum=${this.props.vdatumType}`} target='_blank'>
                      <p className='label'>Widget URL<i className="material-icons">open_in_new</i></p>
                    </Link>
                    <button className="btn-icon" onClick={() => { this.setState({ stepsEnabled: true }) }}><i className="material-icons">help_outline</i> </button>
                  </div>
                  <h1 className="title">{heading}</h1>
                  {Object.keys(sensor.properties.latest_measurement).length === 0 ? (
                  <p>Last updated {moment(sensor.properties.last_measurement.date).panelFromNow()}</p>
                    ):(
                   <p>Last updated {moment(sensor.properties.latest_measurement.date).panelFromNow()}</p>
                    )}
                </div>
              )
            } else {
              return (
                <div>
                  <div className="panel-nav">
                    <p className='label'>Dashboard</p>
                    <button className="btn-icon" onClick={() => { this.setState({ stepsEnabled: true }) }}><i className="material-icons">help_outline</i> </button>
                  </div>
                  <h1 className="title">{heading}</h1>
                </div>
              )
            }
          }} />
          <Route path="/" render={() => {
            return (
              <div>
                <div className="panel-nav">
                  {layers.join() ? (<Link to={`/?datetime_utc=${this.props.datetime_utc}`}>
                    <p className='label'><i className="material-icons">arrow_back</i> Dashboard</p>
                  </Link>) : (<p className='label'>Dashboard</p>)}
                  <button className="btn-icon" onClick={() => { this.setState({ stepsEnabled: true }) }}><i className="material-icons">help_outline</i> </button>
                </div>
                <h1 className="title">Smart Sea Level Sensors</h1>
                <div className="layers">
                  <p className="label">Layers</p>
                  <Form>
                    <Form.Group controlId="formBasicCheckbox">
                      {radioButtons}
                    </Form.Group>
                  </Form>
                </div>
              </div>
            )
          }} />
        </Switch>

        <Steps
          enabled={this.state.stepsEnabled}
          steps={[
            {
              intro: '<h5 align="center">Welcome</h5><p>The Flood Inundation Map for Chatham County displays data from sea level sensors, bridge vulnerability, and flood inundation predictions.</p>',
            },
            {
              element: '.sensors.form-check',
              intro: '<h5 align="center">Sensors</h5><p>Select the <strong>Sensors</strong> layer to see where sea level sensors are located across Chatham County.</p><p>Then on the map, click on a sensor to see its water level measurements for the last two days and to simulate flooding in the area around the sensor.</p>',
              position: 'right',
            },
            {
              element: '.sensors.form-check',
              intro: `<h5 align="center">Sensors</h5><p>A sensor is colored:</p> <ul class="sensor-colors"> <li><strong class="green">Green</strong> if no flooding</li> <li><strong class="orange">Orange</strong> if minor flooding</li> <li><strong class="red">Red</strong> if moderate flooding</li> <li><strong class="purple">Purple</strong> if major flooding</li> <li><strong class="blue">Blue</strong> if it has recent data but not risk rating thresholds</li> <li><strong class="grey">Grey</strong> if it doesn't have recent data</li> </ul>`,
              position: 'right',
            },
            {
              element: '.bridges.form-check',
              intro: '<h5 align="center">Bridges</h5><p>Select the <strong>Bridges</strong> layer to see a handful of bridges across Chatham County.</p><p>Then on the map, click on a bridge to see its status over the last 60 days.</p>',
              position: 'right',
            },
            {
              element: '.layer',
              intro: '<h5 align="center">Flood Inundation</h5><p>Select <strong>Land</strong> to see predicted flood inundation of land.</p><p>Select <strong>Land &amp; small waterways</strong> to see predicted flood inundation of land and small waterways.</p><p>Select <strong>Land &amp; all waterways</strong> to see predicted flood inundation of land, small waterways, and large waterways.</p>',
              position: 'left',
            },
            {
              element: '.opacity-slider',
              intro: '<h5 align="center">Flood Inundation Opacity Slider</h5><p>Change the value of the opacity slider to better view the base map layer under the flood inundation predictions.</p>',
              position: 'left',
            },
            {
              element: '.vdatum',
              intro: ' <h5 align="center">Vertical Datum</h5><p>Select <strong>NAVD 88</strong> to see water level measurements in <em>NAVD 88</em> datum.</p><p>Select <strong>MLLW</strong> to see water level measurements in <em>Mean Lower Low Water</em> datum.</p><p>Select <strong>MHHW</strong> to see water level measurements in <em>Mean Higher High Water</em> datum.</p>',
              position: 'left',
            },
            {
              element: '.resolution',
              intro: '<h5 align="center">Flood Inundation Resolution</h5><p>Change between higher and lower resolutions of the flood inundation predictions.</p>',
              position: 'left',
            },
            {
              element: '.basemap',
              intro: '<h5 align="center">Base Map</h5><p>Change the base map to see different information or make the data stand out.</p>',
              position: 'left',
            }
          ]}
          options={{
            doneLabel: 'Done',
            hideNext: false,
            nextToDone: true,
            showProgress: true,
            disableInteraction: false,
          }}
          initialStep={0}
          onExit={() => { this.setState({ stepsEnabled: false }) }}
        />
      </div>
    );
  }
}

export default withRouter(PanelHeader);
