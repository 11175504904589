
import React, { Component } from 'react';
import ReactSlider from 'react-slider';

import './index.css';

class OpacitySlider extends Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (<div className="opacity-slider">
            <span className="slider-text">Opacity</span>
            <ReactSlider
                className="horizontal-slider"
                marks={[0.2, 0.4, 0.6, 0.8, 1.0]}
                renderMark={(props) => {
                    props.style['color'] = props.key < this.props.opacity ? "white" : "black";
                    return <div {...props}>{100*props.key}</div>
                }}
                markClassName="horizontal-slider-mark"
                min={0.2}
                max={1.0}
                step={0.2}
                defaultValue={0.9}
                thumbClassName="horizontal-slider-thumb"
                trackClassName="horizontal-slider-track"
                renderThumb={(props, state) => <div {...props}>{100*state.valueNow}</div>}
                onChange={(value, index) => { this.props.setOpacity(value) }}
            />
        </div>)

    }
}

export default OpacitySlider