import React, { Component } from 'react';
import { withRouter, matchPath } from "react-router";
import DeckGL, { TileLayer, BitmapLayer, FlyToInterpolator, GeoJsonLayer } from 'deck.gl';
import moment from 'moment';
import { StaticMap } from "react-map-gl";
import mapboxgl from "mapbox-gl";

import OpacitySlider from '../OpacitySlider';
import { LAYERS } from '../../constants/layers';
import * as helpers from '../../helpers';
import {
  getInundationBoundaryLayer,
  getInundationLandLayer,
  getInundationSmallWaterwaysLayer,
  getInundationLargeWaterwaysLayer,
  getInundationPredictionLayer,
  getInundationDepth,
} from './helper';

import './index.css';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialViewState: {
        latitude: 31.98,
        longitude: -81.15,
        zoom: 10,
      },
      isFirstUpdate: true,
      isLatestMap: this.props.datetime_utc === '' ? true : false,

      sensors: false,
      bridges: false,

      activeInundationLayer: 'land-small', // land, land-small, land-small-large
      basemap: 'street',

      opacity: 0.9,
      lowResolution: true,
      userSetResolution: false,

      selectedSensorId: 0,
    }
    
    this.setOpacity = this.setOpacity.bind(this);
  }

  setOpacity(value){
    this.setState({ opacity: value });
  }

  componentDidUpdate(prevProps) {
    if (this.state.isFirstUpdate || (this.props.location.pathname !== prevProps.location.pathname)) {
      let match = null;
      let layer = null;
      let _id = null;
      for (let i = 0; i < LAYERS.length; i += 1) {
        layer = LAYERS[i]
        let path = `/${layer}/:id`;
        match = matchPath(this.props.location.pathname, {
          path: path,
          exact: true,
          strict: false
        });
        if (match !== null) {
          _id = match.url.replace(`/${layer}/`, '');
          break;
        }
      }

      if (match !== null) {
        let entity = helpers.getEntity(this.props, layer, _id);
        if (Object.keys(entity).length > 0) {
          this.setState({
            initialViewState: {
              latitude: entity.geometry.coordinates[1],
              longitude: entity.geometry.coordinates[0],
              zoom: 14,
              transitionDuration: 800,
              transitionInterpolator: new FlyToInterpolator()
            },
            isFirstUpdate: false,
          })
        }
      } else {
        // recenter the map
        this.setState({
          initialViewState: {
            latitude: 31.98,
            longitude: -81.15,
            zoom: 10,
            transitionDuration: 800,
            transitionInterpolator: new FlyToInterpolator()
          },
          isFirstUpdate: false,
        })
      }
    }

    let previousActiveLayers = helpers.getLayers(prevProps);
    let currentActiveLayers = helpers.getLayers(this.props);
    if (this.state.isFirstUpdate || (JSON.stringify(previousActiveLayers) !== JSON.stringify(currentActiveLayers))) {
      for (let i = 0; i < LAYERS.length; i += 1) {
        let layer = LAYERS[i];
        if (currentActiveLayers.includes(layer)) {
          this.setState({
            [layer]: true,
            isFirstUpdate: false,
          })
        } else {
          this.setState({
            [layer]: false,
            isFirstUpdate: false,
          })
        }
      }
    }
  }

  render() {
    const that = this;

    let baseMap;
    let staticMap;
    if (this.state.basemap === 'street') {
      baseMap = new TileLayer({
        id: 'base-map',
        // https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Tile_servers
        data: 'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png',

        minZoom: 0,
        maxZoom: 19,
        tileSize: 256,

        renderSubLayers: props => {
          const {
            bbox: { west, south, east, north }
          } = props.tile;

          return new BitmapLayer(props, {
            data: null,
            image: props.data,
            bounds: [west, south, east, north]
          });
        }
      });
    } else if (this.state.basemap === 'satellite') {
      staticMap = (
        <StaticMap
          mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
          mapboxApiAccessToken="pk.eyJ1IjoibHBvbGVwZWRkaSIsImEiOiJja2xiNWNsMzUyc2kyMzJxbTd5d3FrbmR0In0.BB3-z0haQ-ongr7pg1J1jQ"
        />
      )
    } else if (this.state.basemap === 'dark') {
      staticMap = (
        <StaticMap
          mapStyle="mapbox://styles/mapbox/dark-v10"
          mapboxApiAccessToken="pk.eyJ1IjoibHBvbGVwZWRkaSIsImEiOiJja2xiNWNsMzUyc2kyMzJxbTd5d3FrbmR0In0.BB3-z0haQ-ongr7pg1J1jQ"
        />
      )
    }

    let vdatum = this.props.vdatumType;

    let sensorData = this.props.sensors && this.props.sensors.features.sort(function (a,b) {
      return Object.keys(a.properties.latest_measurement).length - Object.keys(b.properties.latest_measurement).length
    })

    sensorData = sensorData && sensorData.filter(function(feature){
      if(feature.properties && feature.properties.status){
        return feature.properties.status === "active"; // Show only active sensors.
      }
      return true;
    })

    const sensorsLayer = new GeoJsonLayer({
      id: 'sensors-layer',
      data: sensorData,
      visible: this.state.sensors,
      stroked: true,
      lineWidthScale: 1, // set the width of the outline to be 1 pixel
      lineWidthMinPixels: 1,
      getLineColor: [0, 0, 0], // set the color of the outline to black
      getPointRadius: 12,
      pointRadiusUnits: 'pixels',
      getFillColor: feature => {
        if (Object.keys(feature.properties.latest_measurement).length > 0) {
          if ('risk_ratings' in feature.properties) {
            const waterLevel = feature.properties.latest_measurement.water_level
            const confidenceInterval = feature.properties.latest_measurement.confidence_interval
            const latestWaterLevel = waterLevel; //(waterLevel + confidenceInterval);

            const minor = feature.properties.risk_ratings.minor;
            const moderate = feature.properties.risk_ratings.moderate;
            const major = feature.properties.risk_ratings.major;

            // no flooding (green)
            if (latestWaterLevel < minor) {
              return [67, 185, 130, 204]
            }

            // minor flooding (orange)
            else if (latestWaterLevel >= minor && latestWaterLevel < moderate) {
              return [255, 153, 0, 204]
            }

            // moderate flooding (red)
            else if (latestWaterLevel >= moderate && latestWaterLevel < major) {
              return [255, 1, 2, 204]
            }

            // major flooding (purple)
            else if (latestWaterLevel >= major) {
              return [204, 51, 205, 204]
            }
          } else {
            // sensor has data, but no risk ratings (blue)
            return [39, 110, 241, 204]
          }
        } else {
          // sensor doesn't have data (gray)
          return [175, 175, 175, 204]
        }
      },
      pickable: true,
      onClick: (info, event) => {
        that.props.history.push(`/sensors/${info.object.properties.id}?layers=sensors&datetime_utc=${this.props.datetime_utc}`);
        this.props.setSensorSelected(true);
        this.props.setSensorInfo(info.object.properties.latest_measurement.water_level, info.object.properties.vdatum_offsets);
        this.setState({
          selectedSensorId: info.object.properties.id,
        })
      }
    })

    const bridgesLayer = new GeoJsonLayer({
      id: 'bridges-layer',
      data: this.props.bridges,
      visible: this.state.bridges,
      stroked: true,
      lineWidthScale: 1, // set the width of the outline to be 1 pixel
      lineWidthMinPixels: 1.5,
      getLineColor: [255, 255, 0], // set the color of the outline to yellow
      getPointRadius: 12,
      pointRadiusUnits: 'pixels',
      getFillColor: feature => {
        if (Object.keys(feature.properties.events.length > 0)) {
          if (feature.properties.num_events > 0) {
            return [186, 82, 67, 204]
          }
          else
            return [67, 185, 130, 204]
        } else {
          return [175, 175, 175, 204]
        }
      },
      pickable: true,
      onClick: (info, event) => {
        that.props.history.push(`/bridges/${info.object.properties.id}?layers=bridges&datetime_utc=${this.props.datetime_utc}`);
      }
    })

    const activeInundationLayer = this.state.activeInundationLayer;
    const lowResolution = this.state.lowResolution;
    const isSensorSelected = this.props.isSensorSelected;
    const opacity = this.state.opacity;
    const datetimeUrlPath = this.props.datetime_utc ? `/${this.props.datetime_utc}` : '';
    let inundationLayers_Boundary = getInundationBoundaryLayer(activeInundationLayer, lowResolution, isSensorSelected, opacity, datetimeUrlPath)
    let inundationLayers_Land = getInundationLandLayer(activeInundationLayer, lowResolution, isSensorSelected, opacity, datetimeUrlPath)
    let inundationLayers_LandSmall = getInundationSmallWaterwaysLayer(activeInundationLayer, lowResolution, isSensorSelected, opacity, datetimeUrlPath)
    let inundationLayers_LandSmallLarge = datetimeUrlPath === '' ? getInundationLargeWaterwaysLayer(activeInundationLayer, lowResolution, isSensorSelected, opacity, datetimeUrlPath) : [];
    let inundationLayers_prediction = getInundationPredictionLayer(isSensorSelected, this.state.selectedSensorId, this.props.selectedWaterLevel, opacity, datetimeUrlPath)

    // ========================================================================c

    return (
      <div>
        <DeckGL
          controller={true}  // allows user to move the map around
          initialViewState={this.state.initialViewState}
          height="100%"
          width="100%"
          layers={[baseMap, inundationLayers_Boundary, inundationLayers_LandSmall, inundationLayers_Land,
            inundationLayers_LandSmallLarge, sensorsLayer, bridgesLayer, inundationLayers_prediction]}
          getTooltip={(info) => {
            if (info && info.object) {
              const object = info.object;
              const layerID = info.layer && info.layer.id;
              // inundation
              if ('ID' in object) { //INUNDATION_DEPTH
                let depth = getInundationDepth(object.ID, this.props.selectedWaterLevel, this.props.isSensorSelected);
                if (depth > 0) {
                  let roundedInundationDepth = depth.toFixed(2);
                  return {
                    html: `<div class="content">
                      <div class="inundation-depth">
                        <p class="label">${(layerID && layerID.indexOf('inundationLand') > -1) ? 'Inundation depth' : 'Water level' }</p>
                        <p><span class="value">${roundedInundationDepth} ft</p>
                      </div>
                    </div>`,
                    style: {
                      backgroundColor: '#fff',
                    }
                  }
                }
              }

              // sensor
              if ('properties' in object && 'latest_measurement' in object.properties) {
                if (Object.keys(object.properties.latest_measurement).length > 0) {
                  this.props.vdatumOffsets.current = object.properties.vdatum_offsets;
                  return {
                    html: `<div class="content">
                      <h2 class="description">${object.properties.description}</h2>
                      <div class="water-level">
                        <p class="label">Water level</p>
                        <p><span class="value">${helpers.vdatumConverter(object.properties.vdatum_offsets,object.properties.latest_measurement.water_level,vdatum)} ${object.properties.latest_measurement.unit}</span> <span class="confidence-interval">± ${object.properties.latest_measurement.confidence_interval} ${object.properties.latest_measurement.unit}</span> <span class="datum">(${vdatum})</span></p>
                      </div>
                      <div class="last-updated">
                        <p class="label">Last updated</p>
                        <p>${moment(object.properties.latest_measurement.date).mapFromNow()}</p>
                      </div>
                    </div>`,
                    style: {
                      backgroundColor: '#fff',
                    }
                  }
                } else {
                  return {
                    html: `<div class="content">
                      <h2 class="description">${object.properties.description}</h2>
                      <div class="empty">
                        <p>No recent data</p>
                        <p>Last Updated ${moment(object.properties.last_measurement.date).panelFromNow()}</p>
                      </div>
                    </div>`,
                    style: {
                      backgroundColor: '#fff',
                    }
                  }
                }
              }

              // bridges
              if ('properties' in object && 'num_events' in object.properties) {
                let numEventsLabel = `${object.properties.num_events} events in the last 60 days`;
                if (object.properties.num_events === 1) {
                  numEventsLabel = `${object.properties.num_events} event in the last 60 days`;
                }
                if (object.properties.events.length > 0) {
                  return {
                    html: `<div class="content">
                      <h2 class="description">${object.properties.name}</h2>
                      <div class="last-updated">
                        <p class="label">${numEventsLabel}</p>
                      </div>
                    </div>`,
                    style: {
                      backgroundColor: '#fff',
                    }
                  }
                } else {
                  return {
                    html: `<div class="content">
                      <h2 class="description">${object.properties.name}</h2>
                      <div class="empty">
                        <p>No recent data</p>
                      </div>
                    </div>`,
                    style: {
                      backgroundColor: '#fff',
                    }
                  }
                }
              }
            }
          }}
          onViewStateChange={({ viewState }) => {
            if (!this.state.userSetResolution && viewState.zoom > 12 && this.state.lowResolution) {
              this.setState({ lowResolution: false });
            }
          }}
        >
          {staticMap}
        </DeckGL>
        <div className="inundation">
          <div className="layer">
            {!isSensorSelected && <button onClick={() => { this.setState({ activeInundationLayer: 'land' }) }} className={this.state.activeInundationLayer === 'land' ? 'active' : ''}>Land</button>}
            {!isSensorSelected && <button onClick={() => { this.setState({ activeInundationLayer: 'land-small' }) }} className={this.state.activeInundationLayer === 'land-small' ? 'active' : ''}>Land &amp; small waterways</button>}
            {(this.state.isLatestMap || isSensorSelected) && <button disabled={isSensorSelected} onClick={() => { this.setState({ activeInundationLayer: 'land-small-large' }) }} 
            className={(this.state.activeInundationLayer === 'land-small-large' || isSensorSelected) ? 'active' : ''}>Land &amp; all waterways</button>}
          </div>
          <div className="legend">
            <div style={{ backgroundColor: `rgb(239,243,255,${0.5 + opacity/2})` }}>&lt; 6 in</div>
            <div style={{ backgroundColor: `rgb(189,215,231,${0.5 + opacity/2})` }}>6-12 in</div>
            <div style={{ backgroundColor: `rgb(107,174,214,${0.5 + opacity/2})` }}>12-18 in</div>
            <div style={{ color: '#fff', backgroundColor: `rgb(49,130,189,${0.5 + opacity/2})` }}>18-24 in</div>
            <div style={{ color: '#fff', backgroundColor: `rgb(8,81,156,${0.5 + opacity/2})` }}>&gt; 24 in</div>
          </div>
          <OpacitySlider opacity={this.state.opacity} setOpacity={this.setOpacity}/>
          <div className="vdatum">
            <button onClick={() => { this.props.onVdatumChange('NAVD 88') }} className={this.props.vdatumType === 'NAVD 88' ? 'active' : ''}>NAVD 88</button>
            <button onClick={() => { this.props.onVdatumChange('MLLW') }} className={this.props.vdatumType === 'MLLW' ? 'active' : ''}>MLLW</button>
            <button onClick={() => { this.props.onVdatumChange('MHHW') }} className={this.props.vdatumType === 'MHHW' ? 'active' : ''}>MHHW</button>
          </div>
          {<div className="resolution">
            {!this.props.isSensorSelected && <button  className={this.state.lowResolution ? '' : 'active'} 
              onClick={() => { this.setState({ lowResolution: !this.state.lowResolution, userSetResolution: true }) }}>
              {this.state.lowResolution ? 'Low Resolution' : 'High Resolution'} </button>}
            {this.props.isSensorSelected && <button className='active' disabled={true}>{'High Resolution'}</button>}
          </div>}
        </div>
        {!this.props.isSensorSelected && helpers.getLayers(this.props)[0] === 'bridges' && (
          <div className="sensor-bridge-legend">
            <div className="legend-item">
              <div className="legend-circle-bridge" style={{ backgroundColor: 'rgba(67, 185, 130, 0.8)' }}></div>
              <span>Bridge - no events </span>
            </div>
            <div className="legend-item">
              <div className="legend-circle-bridge" style={{ backgroundColor: 'rgba(186, 82, 67, 0.8)' }}></div>
              <span>Bridge - one or more events </span>
            </div>
          </div>
        )}
        {!this.props.isSensorSelected && helpers.getLayers(this.props)[0] === 'sensors' && (
          <div className="sensor-bridge-legend">
            <div className="legend-item">
              <div className="legend-circle-sensor" style={{ backgroundColor: 'rgba(67, 185, 130, 0.8)' }}></div>
              <span>Sensor - no flooding</span>
            </div>
            <div className="legend-item">
              <div className="legend-circle-sensor" style={{ backgroundColor: 'rgba(255, 153, 0, 0.8)' }}></div>
              <span>Sensor - minor flooding</span>
            </div>
            <div className="legend-item">
              <div className="legend-circle-sensor" style={{ backgroundColor: 'rgba(255, 1, 2, 0.8)' }}></div>
              <span>Sensor - moderate flooding</span>
            </div>
            <div className="legend-item">
              <div className="legend-circle-sensor" style={{ backgroundColor: 'rgba(204, 51, 205, 0.8)' }}></div>
              <span>Sensor - major flooding</span>
            </div>
            <div className="legend-item">
              <div className="legend-circle-sensor" style={{ backgroundColor: 'rgba(39, 110, 241, 0.8)' }}></div>
              <span>Sensor - no risk rating</span>
            </div>
            <div className="legend-item">
              <div className="legend-circle-sensor" style={{ backgroundColor: 'rgba(175, 175, 175, 0.8)' }}></div>
              <span>Sensor - no recent data</span>
            </div>
          </div>
        )}
        <div className="basemap">
          <button onClick={() => { this.setState({ basemap: 'street' }) }} className={this.state.basemap === 'street' ? 'active' : ''}>
            Street
          </button>
          <button onClick={() => { this.setState({ basemap: 'satellite' }) }} className={this.state.basemap === 'satellite' ? 'active' : ''}>
            Sat
          </button>
          <button onClick={() => { this.setState({ basemap: 'dark' }) }} className={this.state.basemap === 'dark' ? 'active' : ''}>
            Dark
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(Map);
